import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import backgroundImage from "./images/wc logo2.webp";
import Header from './Header';
import Footer from './Footer';

const News = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  const [showFullContent, setShowFullContent] = useState(false);

  //const content = `this the brief news, of which if we want to read the whole news item, we need to click on view or see more button. after reading, we can also click on view less for us to hide the unwanted content and manage the screen well`;

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };


  const [newsItems, setNewsItems] = useState([]);
  const [expandedNews, setExpandedNews] = useState({});
/*
  useEffect(() => {
    // Fetch the latest 4 news items
    axios.get(`${server_app_api_php}/api/news/latest?limit=4`)
      .then(response => setNewsItems(response.data))
      .catch(error => console.error('Error fetching news:', error));
  }, []);
*/

const fetchNews = async () => {
//const handleSubmit = async (e) => {

  const formData = new FormData();
  formData.append('apiType', 'get-news');
try {
    //const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-news`, formData, {
      const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
      setNewsItems(response.data);
      //console.log(response.data);
  } catch (error) {
    console.log('An error occurred while retrieving news items.');
  }
};

useEffect(() => {
  fetchNews();
}, []);

  const toggleContentLatestNews = (index) => {
    setExpandedNews(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <>

<div>
<Header />
    

  <main>

 <div class="container-fluid background-r">
   {/* <div className="container">
  {/* Section: News */}
  <section id="news" className="mt-5 mb-4">

    <h2 className="mdb-color-text darken-3 text-uppercase center font-weight-bold mb-2 pt-4 wow fadeIn" data-wow-delay=".2s">Woodlands Conference AY News</h2>

    <hr className=" mb-4"/>

    {newsItems.map((news, index) => (
      <>
    <div className="row mb-5 pt-3 wow fadeIn" data-wow-delay=".4s">
<div className="col-lg-4 mb-md-0 mb-4">
        <div className="view overlay z-depth-1">
          {news.media_urlsVideos.length < 1 && news.media_urlsImages.length > 0 && (
                      <img src={server_app_api_php+'/server/'+news.media_urlsImages[0]} className="img-fluid z-depth-1-half" alt="" style={{width:'100%',height:'300px'}} />
                    )}
 {news.media_urlsVideos.length > 0 && (
            <video width="100%" height="315"   controls>
            <source src={server_app_api_php+'/server/'+news.media_urlsVideos[0]}  />
            Your browser does not support the video tag.
          </video>
)}

          <div className="mask rgba-white-slight"></div>
        </div>
      </div>
      <div className="col-lg-7 ml-lg-4 mb-5">
        <h4 className="text-uppercase mb-4"><a href="#!" className="black-text-2 font-weight-bold pb-3"> <b class='mr-2'>|</b> 
        {news.title}
          </a></h4>
        <p className="grey-text-3 font-thin" align="justify">
        {expandedNews[index] ? news.description : news.description.split(' ').slice(0, 20).join(' ')}
                      {news.description.split(' ').length > 20 && (
                        <span>
                          ... <button onClick={() => toggleContentLatestNews(index)} className="btn btn-link p-0">{expandedNews[index] ? 'Hide' : 'View More'}</button>
                        </span>
                      )}
        </p>

        {expandedNews[index] && (
                      <div className="row additional-media mt-3">

                        {news.media_urlsImages.slice(0).map((urlImage, indexImage) => (
                          <div className='view overlay z-depth-1 col-md-3 '>
                          <img key={indexImage} src={server_app_api_php+'/server/'+urlImage} alt={`News ${indexImage + 1}`} className="img-fluid mt-2" />
                        </div>
                        ))}
                        
                        {news.media_urlsVideos.slice(0).map((urlVideo, indexVideo) => (
                          <div className='view overlay z-depth-1 col-md-12 mt-2 '>
                         <video width="100%" height="315"  key={indexVideo}  controls>
                          <source src={server_app_api_php+'/server/'+urlVideo}  />
                          Your browser does not support the video tag.
                          </video>

                          </div>
                        ))}
                      </div>
                    )}

      </div>
</div>
      <hr class="mb-5" />
      </>
    ))}

  </section>

  
</div>


  </main>
 
 
  <Footer />
        
      </div>
    </>
  )
}
export default News
