import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import $ from 'jquery'
//import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables'
import HeaderAdmin from '../HeaderAdmin';
import {
    MDBIcons,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon
  } from 'mdb-react-ui-kit';

const Secretariat = () => {
  let [handleReportSubmitBtn, setHandleReportSubmitBtn] = useState(false)
  let [handleAnnouncementsSubmitBtn, setHandleAnnouncementsSubmitBtn] = useState(false)
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  let [handleLoader, setHandleLoader] = useState('')
  const [DBView, setDBView] = useState([])


  const [iconsActive, setIconsActive] = useState('tab1');

  const handleIconsClick = value => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  const [reportsData, setReportsData] = useState({
    title: '',
    description: '',
    department: '',
    images: [],
    videos: [],
    documents: [],
  });
 
  const [statusReports, setStatusReports] = useState('');

  // Handle form input changes
  const handleInputChangeReports = (e) => {
    const { name, value } = e.target;
    setReportsData({
      ...reportsData,
      [name]: value,
    });
  };

  // Handle multiple file input change for images
  const handleImageChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      images: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleVideoChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      videos: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleDocumentChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      documents: [...e.target.files],
    });
  };

 // Handle form submission
 const handleSubmitReports = async (e) => {
    e.preventDefault();
    setHandleReportSubmitBtn(true);
    const formData = new FormData();
    formData.append('apiType', 'upload-reports');
    formData.append('title', reportsData.title);
    formData.append('description', reportsData.description);
    formData.append('department', reportsData.department);

    // Append multiple images to FormData
    reportsData.images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    // Append multiple videos to FormData
    reportsData.videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video);
    });

     // Append multiple documents to FormData
     reportsData.documents.forEach((document, index) => {
        formData.append(`documents[${index}]`, document);
      });

    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-news`, formData, {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setStatusReports('Report uploaded successfully!');
        setReportsData({
          title: '',
          description: '',
          department: '',
          images: [],
          videos: [],
          documents: [],
        });
        setHandleReportSubmitBtn(false);        
      } else {
        setStatusReports('Failed to upload Report.');
        setHandleReportSubmitBtn(false);
      }
    } catch (error) {
      setStatusReports('An error occurred while uploading Report.');
      setHandleReportSubmitBtn(false);
    }
  };


  const [announcementsData, setAnnouncementsData] = useState({
    title: '',
    description: '',
    department: '',
    images: [],
    videos: [],
    documents: [],
  });
 
  const [statusAnnouncements, setStatusAnnouncements] = useState('');

  // Handle form input changes
  const handleInputChangeAnnouncements = (e) => {
    const { name, value } = e.target;
    setAnnouncementsData({
      ...announcementsData,
      [name]: value,
    });
  };

  // Handle multiple file input change for images
  const handleImageChangeAnnouncements = (e) => {
    setAnnouncementsData({
      ...announcementsData,
      images: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleVideoChangeAnnouncements = (e) => {
    setAnnouncementsData({
      ...announcementsData,
      videos: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleDocumentChangeAnnouncements = (e) => {
    setAnnouncementsData({
      ...announcementsData,
      documents: [...e.target.files],
    });
  };

 // Handle form submission
 const handleSubmitAnnouncements = async (e) => {
    e.preventDefault();
    setHandleAnnouncementsSubmitBtn(true);
    const formData = new FormData();
    formData.append('apiType', 'upload-announcements');
    formData.append('title', announcementsData.title);
    formData.append('description', announcementsData.description);
    formData.append('department', announcementsData.department);

    // Append multiple images to FormData
    announcementsData.images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    // Append multiple videos to FormData
    announcementsData.videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video);
    });

     // Append multiple documents to FormData
     announcementsData.documents.forEach((document, index) => {
        formData.append(`documents[${index}]`, document);
      });

    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-news`, formData, {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setStatusAnnouncements('Announcements uploaded successfully!');
        setAnnouncementsData({
          title: '',
          description: '',
          department: '',
          images: [],
          videos: [],
          documents: [],
        });
        setHandleAnnouncementsSubmitBtn(false);
      } else {
        setStatusAnnouncements('Failed to upload Announcements.');
        setHandleAnnouncementsSubmitBtn(false);
      }
    } catch (error) {
      setStatusAnnouncements('An error occurred while uploading Announcements.');
      setHandleAnnouncementsSubmitBtn(false);
    }
  };

  const [statusDepartmentReports, setStatusDepartmentReports] = useState('');
  const [statusGeneralReports, setStatusGeneralReports] = useState('');
  const [statusViewAnnouncements, setStatusViewAnnouncements] = useState('');


  
  const [getReports, setGetReports] = useState([]);
const fetchReports = async () => {
      const formData = new FormData();
      formData.append('apiType', 'get-reports');
      formData.append('department', 'Secretariat');
    try {
          const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
          setGetReports(response.data);
          console.log(response.data);

          setTimeout(function () {
            $('#table').DataTable({
              retrieve: true, // this hides the errors
              pageLength: 5,
              lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
              layout: {
                topEnd: {
                    search: {
                        text: '',
                        placeholder: 'Search here'
                    }
                }
            }
            })
          }, 2000)
          
      } catch (error) {
        alert('An error occurred while retrieving Reports. Check Your Internet Connectivity');
      }
    };    
    useEffect(() => {
        fetchReports();
    }, []);

      
  const [getGeneralReports, setGetGeneralReports] = useState([]);
  const fetchGeneralReports = async () => {
        const formData = new FormData();
        formData.append('apiType', 'get-General-reports');
        formData.append('department', 'Secretariat');
      try {
            const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setGetGeneralReports(response.data);
            //console.log(response.data);
  
            setTimeout(function () {
              $('#tableGeneralReports').DataTable({
                retrieve: true, // this hides the errors
                pageLength: 5,
                lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
                layout: {
                  topEnd: {
                      search: {
                          text: '',
                          placeholder: 'Search here'
                      }
                  }
              }
              })
            }, 2000)
            
        } catch (error) {
          alert('An error occurred while retrieving Reports. Check Your Internet Connectivity');
        }
      };    
      useEffect(() => {
          fetchGeneralReports();
      }, []);

        
  const [getAnnouncements, setGetAnnouncements] = useState([]);
  const fetchAnnouncements = async () => {
        const formData = new FormData();
        formData.append('apiType', 'get-announcements');
        formData.append('department', 'Secretariat');
      try {
            const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setGetAnnouncements(response.data);
            //console.log(response.data);
  
            setTimeout(function () {
              $('#tableAnnouncements').DataTable({
                retrieve: true, // this hides the errors
                pageLength: 5,
                lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
                layout: {
                  topEnd: {
                      search: {
                          text: '',
                          placeholder: 'Search here'
                      }
                  }
              }
              })
            }, 2000)
            
        } catch (error) {
          alert('An error occurred while retrieving Announcements. Check Your Internet Connectivity');
        }
      };    
      useEffect(() => {
          fetchAnnouncements();
      }, []);
  

  return (
    <>

<div>
<HeaderAdmin />

  <main>

  <div class="container">

  <div hidden={handleLoader ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>

  <section id="news" class="mt-5 mb-4">

          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 pt-4 wow fadeIn" data-wow-delay=".2s">Secretariat Office</h2>
<hr class=" mb-4" />

<MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
            <MDBIcon fas icon='edit' className='me-2' /> Upload Report
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
            <MDBIcon fas icon='microphone' className='me-2' /> Upload Announcements
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
            <MDBIcon fas icon='table' className='me-2' /> View Department Reports
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
            <MDBIcon fas icon='table' className='me-2' /> View General Reports
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab5')} active={iconsActive === 'tab5'}>
            <MDBIcon fas icon='table' className='me-2' /> View Announcements
          </MDBTabsLink>
        </MDBTabsItem>

      </MDBTabs>

    
      <MDBTabsContent>

        <MDBTabsPane open={iconsActive === 'tab1'}>
        <h2 className="text-center mb-4">Upload Reports</h2>            
        {statusReports && <div className="alert alert-info text-center">{statusReports}</div>}
      <form onSubmit={handleSubmitReports} className="border p-4 shadow-sm rounded">
        
        <div className="form-group mb-3">
          <label htmlFor="title" className="form-label">Title <span class='red-text'>*</span> </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={reportsData.title}
            onChange={handleInputChangeReports}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description" className="form-label">Description / Narration <span class='red-text'>*</span> </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="4"
            value={reportsData.description}
            onChange={handleInputChangeReports}
            required
          ></textarea>
        </div>

        <div class = 'row'>
        <div className="mb-3 col-md-3">
          <label htmlFor="department" className="form-label">Department <span class='red-text'>*</span></label>
          <select
            className="form-control"
            id="department"
            name="department"
            value={reportsData.department}
            onChange={handleInputChangeReports}
            required
          >
            <option value="">Select Department</option>
            <option>Director</option>
            <option>Chairperson</option>
            <option>Secretariat</option>
            <option>Treasury</option>
            <option>Planning</option>
            <option>Parliamentarian</option>
            <option>Projects</option>
            <option>Education</option>
            <option>Communication</option>
            <option>Chaplaincy</option>
          </select>
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="images" className="form-label">Upload Images</label>
          <input
            type="file"
            className="form-control"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageChangeReports}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="videos" className="form-label">Upload Videos</label>
          <input
            type="file"
            className="form-control"
            id="videos"
            name="videos"
            accept="video/*"
            multiple
            onChange={handleVideoChangeReports}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="documents" className="form-label">Upload Documents</label>
          <input
            type="file"
            className="form-control"
            id="documents"
            name="documents"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.odt,.ods,.odp"
            multiple
            onChange={handleDocumentChangeReports}
          />
        </div>


        </div>

        <button type="submit" className="btn btn-primary w-100"
        disabled={handleReportSubmitBtn}>Submit Reports      
        <div class="spinner-border" role="status" hidden = {!handleReportSubmitBtn}></div>
        </button>
      </form>
    
        </MDBTabsPane >

        <MDBTabsPane open={iconsActive === 'tab2'} >
            
      <h2 className="text-center mb-4">Upload Announcements</h2>
      {statusAnnouncements && <div className="alert alert-info text-center">{statusAnnouncements}</div>}
      <form onSubmit={handleSubmitAnnouncements} className="border p-4 shadow-sm rounded">
        
        <div className="form-group mb-3">
          <label htmlFor="title" className="form-label">Title <span class='red-text'>*</span> </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={announcementsData.title}
            onChange={handleInputChangeAnnouncements}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description" className="form-label">Description / Narration <span class='red-text'>*</span> </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="4"
            value={announcementsData.description}
            onChange={handleInputChangeAnnouncements}
            required
          ></textarea>
        </div>

        <div class = 'row'>
        <div className="mb-3 col-md-3">
          <label htmlFor="department" className="form-label">Department <span class='red-text'>*</span></label>
          <select
            className="form-control"
            id="department"
            name="department"
            value={announcementsData.department}
            onChange={handleInputChangeAnnouncements}
            required
          >
            <option value="">Select Department</option>
            <option>Director</option>
            <option>Chairperson</option>
            <option>Secretariat</option>
            <option>Treasury</option>
            <option>Planning</option>
            <option>Parliamentarian</option>
            <option>Projects</option>
            <option>Education</option>
            <option>Communication</option>
            <option>Chaplaincy</option>
          </select>
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="images" className="form-label">Upload Images</label>
          <input
            type="file"
            className="form-control"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageChangeAnnouncements}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="videos" className="form-label">Upload Videos</label>
          <input
            type="file"
            className="form-control"
            id="videos"
            name="videos"
            accept="video/*"
            multiple
            onChange={handleVideoChangeAnnouncements}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="documents" className="form-label">Upload Documents</label>
          <input
            type="file"
            className="form-control"
            id="documents"
            name="documents"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.odt,.ods,.odp"
            multiple
            onChange={handleDocumentChangeAnnouncements}
          />
        </div>

        </div>

        <button type="submit" className="btn btn-primary w-100"
        disabled={handleAnnouncementsSubmitBtn}>Submit Announcements      
        <div class="spinner-border" role="status" hidden = {!handleAnnouncementsSubmitBtn}></div>
        </button>
      </form>
    
        </MDBTabsPane>

        <MDBTabsPane open={iconsActive === 'tab3'} >            
      <h2 className="text-center mb-4">View Department Reports</h2>
      {statusDepartmentReports && <div className="alert alert-info text-center">{statusDepartmentReports}</div>}

      <table
                        id='table'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getReports.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.created_at}</td>
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                                <td>
                                    
                                <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    data-toggle='modal'
    data-target='#modalViewPlotsCustomers'
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-file"></span>View Documents</a>
)}

           </div>
          </li></ul>


                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

     </MDBTabsPane>

     <MDBTabsPane open={iconsActive === 'tab4'} >            
      <h2 className="text-center mb-4">View General Reports</h2>
      {statusGeneralReports && <div className="alert alert-info text-center">{statusGeneralReports}</div>}

      <table
                        id='tableGeneralReports'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getGeneralReports.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.created_at}</td>
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                                <td>
                                    
                                <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    data-toggle='modal'
    data-target='#modalViewPlotsCustomers'
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-file"></span>View Documents</a>
)}

           </div>
          </li></ul>


                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

     </MDBTabsPane>

     <MDBTabsPane open={iconsActive === 'tab5'} >            
      <h2 className="text-center mb-4">View Announcements</h2>
      {statusViewAnnouncements && <div className="alert alert-info text-center">{statusViewAnnouncements}</div>}

      <table
                        id='tableAnnouncements'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getAnnouncements.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.created_at}</td>
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                                <td>
                                    
                                <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    data-toggle='modal'
    data-target='#modalViewPlotsCustomers'
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-file"></span>View Documents</a>
)}

           </div>
          </li></ul>


                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

     </MDBTabsPane>

      </MDBTabsContent>

</section>
</div>


 

  </main>
 
        
      </div>
    </>
  )
}

export default Secretariat
