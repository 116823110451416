import { useRef, useState, useEffect, useContext } from 'react'
// Import FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'
import { faUserTie, faChair, faFileAlt, faPiggyBank, faChartLine, faGavel, faClipboardList, faBook, faComments, faPrayingHands } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';

const Reports = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    const [report, setReport] = useState([]);
    const [reportDepartment, setReportDepartment] = useState("");
    let [handleLoader, setHandleLoader] = useState(false);
  const departments = [
    { name: 'Director', icon: faUserTie },
    { name: 'Chairperson', icon: faChair },
    { name: 'Secretariat', icon: faFileAlt },
    { name: 'Treasury', icon: faPiggyBank },
    { name: 'Planning', icon: faChartLine },
    { name: 'Parliamentarian', icon: faGavel },
    { name: 'Projects', icon: faClipboardList },
    { name: 'Education', icon: faBook },
    { name: 'Communication', icon: faComments },
    { name: 'Chaplaincy', icon: faPrayingHands },
  ];

  const viewReports = async (dept) => {
    setHandleLoader(true);
    const formData = new FormData();
      formData.append('apiType', 'get-reports');
      formData.append('department', dept);
    try {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
          setReport(response.data);
          setReportDepartment(dept);
          setHandleLoader(false);
          //console.log(response.data);
      } catch (error) {
        alert('An error occurred while retrieving Report. Check Internet Connection');
        setHandleLoader(false);
      }
      
    };


    let [fileDoc, setFileDoc] = useState('')
  let [givenFile, setGivenFile ]= useState('')

  const downloadReport = file => {
    //alert(file);
    setFileDoc(file);
    setGivenFile(`${file}`);
}

    useEffect(() => {
      if(fileDoc != '')
      downloadDoc();
    }, [fileDoc]);
    
    const downloadDoc = async () => {
     //alert("Your Download Will Start Soon")
      try {
              const response = await axios.get(server_app_api_php+'/server/downloadReports.php?file='+givenFile, {
                  responseType: 'blob'
              });
              if (response.status === 200) {
                //alert(response.data);
                  const blob = new Blob([response.data], { type: 'application/octet-stream' });
                  const downloadLink = document.createElement('a');
                  downloadLink.href = window.URL.createObjectURL(blob);
                  downloadLink.download = fileDoc;
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
              }
          } catch (error) {
              alert('Error downloading file:', error);
          }
          setFileDoc('')
          setGivenFile('')
    }

  return (
    <div>
        <Header />
        <main class='mt-5'>
    <div className="container my-5 mt-5">
   
    
    {/**************Modal For Plot Customer Statement Starts Here****************/}
        <div className="modal fade" id="modalViewReports" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-fluid modal-notify modal-success" role="document">
            {/* Content */}
            <div className="modal-content">
              {/* Header */}
              <div className="modal-header">
                <p className="heading lead">View Reports</p>

                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" className="white-text">&times;</span>
                </button>
              </div>

              {/* Body */}
              <div className="modal-body ">
               
			    {/* Second column */}
          <div className="col-md-12 col-lg-12 col-xl-12 mb-md-0 mb-5 ">

            {/* Card */}
            <div className="card profile-card ">
              <div className="card-body pt-0 mt-0">                        
              <div className='mt-5'>            
            <div className='text-center'>
          <h1 className='font-weight-bold text-uppercase'>{reportDepartment}</h1>
        </div>
                
        <div className='row py-3 morpheus-den-gradient accent-2 white-text'>

        <div hidden={!handleLoader}>
        <div id="overlayer"></div>
       <div class="loader d-flex justify-content-center">
     <div class="spinner-border white-text" style={{width: '10rem', height: '10rem'}} role="status">
         <span class='visually-hidden'>Retrieving Reports...</span>
       </div>
     </div>
     </div>
      
                    {report.map(val => {
                      return (
                        <>
                          <div class='col-xl-4 col-md-6 mb-4'>
                            {/*<!-- Panel -->*/}
                            <div class='card'>
                              <div
                                class='card-header text-uppercase white-text info-color'>
                                {val.created_at}
                              </div>

                              <div class='row mt-1'>
                                <div class='col-md-12 col-12 text-left ml-3 pr-5 pl-3 wrap'>
                                  <div class='font-small grey-text'>
                                  Title :
                                    <span class='ml-1 mt-1 mb-1 font-weight-bold white-text'>
                                      {val.title}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Description :
                                    <span class='mt-1 font-weight-bold white-text wrap'>
                                      {val.description}
                                    </span>
                                  </div>
                                  <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block">Attachments Here</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    onClick={() => { downloadReport(val.media_urlsImages)  }}
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
onClick={() => { downloadReport(val.media_urlsVideos)  }}
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
onClick={() => { downloadReport(val.media_urlsDocuments)  }}
><span class = "fa fa-file"></span>View Documents</a>
)}
           </div>
          </li></ul>
                                </div>
                              </div>
                              {/*<!-- Card content -->*/}
                            </div>
                            {/*<!-- Panel -->*/}
                          </div>
                        </>
                      )
                    })}
                    {/*<!-- Grid column -->*/}
                  </div>
                  {/* <!-- Grid row -->*/}
               </div>

             
				<div className="row">

                </div>
				
			</div>

            </div>
            {/* Card */}

          </div>
          {/* Second column */}
			   
              </div>

              {/* Footer */}
              <div className="modal-footer">
                <div className='row'>
                  <div className='col-md-6 left'>
                
                  </div>
                  <div className='col-md-6 text-right'>
                  <a type="button" className="btn btn-outline-success waves-effect" data-dismiss="modal">Close</a>
                  </div>
                </div>
              </div>
            </div>
            {/* Content */}
          </div>
        </div>

    {/**************Modal For Plot Customer Statement Ends Here *****************/}
      
      <div className="row">
        <div className="col-12 text-center mb-4 mt-5">
        <h2 className="text-center mb-4 mt-5">REPORTS</h2>
        </div>
        {departments.map((department, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title d-flex align-items-center">
                  <FontAwesomeIcon icon={department.icon} className="me-2 mr-1" />
                  {department.name}
                </h5>
                <p className="card-text">
                  
                </p>
                <button className="btn btn-primary"
                 data-toggle='modal'
    data-target='#modalViewReports'

    onClick={() => {
        viewReports(department.name)
      }}
                >View Reports</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </main>

    <Footer />
    </div>
  );
};

export default Reports;
