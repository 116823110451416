import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './PCM.css';
import planningCommitImage from './images/pcm2.jpg';

const PCM = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="overlay">
                <Header />
                <hr className="mt-5 mb-5" />
                <main>
                    <div className="container mt-5">
                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed scrolling-navbar">
                            <div className="container">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={toggleNavbar}
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="Adventurers" title='Adventurers Ministries'>Adventurers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Pathfinders" title='Pathfinders Ministries'>Pathfinders</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="Ambassadors" title='Ambassadors Ministries'>Ambassadors</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="YoungAdults" title='Young Adults Ministries'>Young Adults</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        <section className="container-fluid mt-5">
                            <article className="c-article l-grid-item l-grid-item--l--4-col l-grid-item--xxl--3-col post-1839 page type-page status-publish format-standard hentry">
                                <div className="mt-5">
                                    <div className="">
                                        
{/*                          
                                    <div className="row d-flex">
  <div className="col-md-6 mb-3 d-flex">
    <div className="card flex-fill h-100">
      <div className="card-header">
        <span className="mt-5 text-uppercase">Vision</span>
      </div>
      <div className="card-body">
        <p>
          The goal of the General Conference Public Campus Ministry is to see young people grounded in the mission of Jesus Christ and the Seventh-day Adventist Church, and able to reach and engage their fellow campus students in life-long relationships with Jesus. Furthermore, Public Campus Ministry not only concerns itself with nurturing students but also supporting Adventist professors and others who work at public campuses. We work together in collaboration with the Divisions, Unions, Conferences, and attached fields to help accomplish this because we know that one of the best ways to keep youth and young adults from leaving their faith as college students is to train and permit them to lead. Young people are valuable participants in this ministry. We all look to the local church as the real home of public campus ministry.
        </p>
        <p>
          <a href="https://www.gcyouthministries.org/resources/public-campus-ministries/amicus/">
            AMiCUS &ndash; (Adventist Ministry to College and University Students)
          </a>
        </p>
      </div>
    </div>
  </div>
  
  <div className="col-md-6 mb-3 d-flex">
    <div className="card flex-fill h-100">
      <div className="card-header">
        <span className="mt-5 text-uppercase">Objectives of Online Discussion PCM Webinars</span>
      </div>
      <div className="card-body">
        <ul>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To promote a vibrant Public Campus Ministry</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To prepare students to deal with intellectual challenges that arise in a secular environment</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To equip Chapter/Association/Fellowship Leaders</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To provide for the needs of Students on Public Campuses</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To equip students with the necessary skills and competencies to navigate Campus life</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To encourage students to “GO” by training them for outreach and witnessing on and off campus</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To inspire Adventist students to increase their commitment to Christ</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To motivate Adventist students to achieve excellence in all spheres</li>
        </ul>
      </div>
    </div>
  </div>
</div>
*/}

<div id="Mission-Aim-Motto-And-Pledge" className="row mb-2 mt-5 d-flex">
 
 <div className="col-md-6 mb-3 d-flex align-items-stretch">
    <div className="card text-white h-100">
      <div className="card-header text-center">
       
        <span className="mt-2 text-uppercase">Vision</span>
      </div>
      <div className="card-body d-flex flex-column justify-content-start">
      <p>
          The goal of the General Conference Public Campus Ministry is to see young people grounded in the mission of Jesus Christ and the Seventh-day Adventist Church, and able to reach and engage their fellow campus students in life-long relationships with Jesus. Furthermore, Public Campus Ministry not only concerns itself with nurturing students but also supporting Adventist professors and others who work at public campuses. We work together in collaboration with the Divisions, Unions, Conferences, and attached fields to help accomplish this because we know that one of the best ways to keep youth and young adults from leaving their faith as college students is to train and permit them to lead. Young people are valuable participants in this ministry. We all look to the local church as the real home of public campus ministry.
        </p>
        <p>
          <a href="https://www.gcyouthministries.org/resources/public-campus-ministries/amicus/">
            AMiCUS &ndash; (Adventist Ministry to College and University Students)
          </a>
        </p>
      </div>
    </div>
  </div>

 <div className="col-md-6 mb-3 d-flex align-items-stretch">
    <div className="card text-white h-100">
      <div className="card-header text-center">
        
        <span className="mt-2 text-uppercase" >Objectives of Online Discussion PCM Webinars</span>
      </div>
      <div className="card-body d-flex flex-column justify-content-start">
      <ul>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To promote a vibrant Public Campus Ministry</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To prepare students to deal with intellectual challenges that arise in a secular environment</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To equip Chapter/Association/Fellowship Leaders</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To provide for the needs of Students on Public Campuses</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To equip students with the necessary skills and competencies to navigate Campus life</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To encourage students to “GO” by training them for outreach and witnessing on and off campus</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To inspire Adventist students to increase their commitment to Christ</li>
          <li><FontAwesomeIcon icon={faCheck} className="li-icon" /> To motivate Adventist students to achieve excellence in all spheres</li>
        </ul>
      </div>
    </div>
  </div>
</div>

                                    </div>
                                </div>
                            </article>
                        </section>
                    </div>
                </main>
                <Footer />
            </div>
            <div className="overlay-background" style={{ backgroundImage: `url(${planningCommitImage})` }}></div>
        </>
    );
}

export default PCM;
