import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'
import $ from 'jquery'
//import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/js/dataTables.dataTables'
import HeaderAdmin from '../HeaderAdmin';
import {
    MDBIcons,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBIcon
  } from 'mdb-react-ui-kit';

const Communication = () => {
  let [handleReportsSubmitBtn, setHandleReportsSubmitBtn] = useState(false)
  let [handleNewsItemSubmitBtn, setHandleNewsItemSubmitBtn] = useState(false)
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [expandedNews, setExpandedNews] = useState({});
  const toggleContentLatestNews = (index) => {
    setExpandedNews(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  /*********The Counter To Refresh The Data Info Views Starts Here*****************/
  let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 360000
  setInterval(() => {
    setCount(count + 1)
  }, delay)
  /*********The Counter To Refresh The Data Info Views Ends Here**********************/

  let [handleLoader, setHandleLoader] = useState('')
  const [DBView, setDBView] = useState([])


  const [iconsActive, setIconsActive] = useState('tab1');

  const handleIconsClick = value => {
    if (value === iconsActive) {
      return;
    }

    setIconsActive(value);
  };

  const [newsData, setNewsData] = useState({
    title: '',
    description: '',
    category: '',
    images: [],
    videos: [],
    documents: [],
  });
  const [status, setStatus] = useState('');

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewsData({
      ...newsData,
      [name]: value,
    });
  };

  // Handle multiple file input change for images
  const handleImageChange = (e) => {
    setNewsData({
      ...newsData,
      images: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleVideoChange = (e) => {
    setNewsData({
      ...newsData,
      videos: [...e.target.files],
    });
  };
  
   // Handle multiple file input change for videos
   const handleDocumentChange = (e) => {
    setNewsData({
      ...newsData,
      documents: [...e.target.files],
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setHandleNewsItemSubmitBtn(true);
    const formData = new FormData();
    formData.append('apiType', 'upload-news');
    formData.append('title', newsData.title);
    formData.append('description', newsData.description);
    formData.append('category', newsData.category);

    // Append multiple images to FormData
    newsData.images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    // Append multiple videos to FormData
    newsData.videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video);
    });

    // Append multiple documents to FormData
    newsData.documents.forEach((documents, index) => {
      formData.append(`documents[${index}]`, documents);
    });

    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-news`, formData, {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setStatus('News item uploaded successfully!');
        setNewsData({
          title: '',
          description: '',
          category: '',
          images: [],
          videos: [],
          documents: [],
        });
        setHandleNewsItemSubmitBtn(false);
      } else {
        setStatus('Failed to upload news item.');
        setHandleNewsItemSubmitBtn(false);
      }
    } catch (error) {
      setStatus('An error occurred while uploading news item.');
      setHandleNewsItemSubmitBtn(false);
    }
  };


  const [reportsData, setReportsData] = useState({
    title: '',
    description: '',
    department: '',
    images: [],
    videos: [],
    documents: [],
  });

  const [statusReports, setStatusReports] = useState('');

  // Handle form input changes
  const handleInputChangeReports = (e) => {
    const { name, value } = e.target;
    setReportsData({
      ...reportsData,
      [name]: value,
    });
  };

  // Handle multiple file input change for images
  const handleImageChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      images: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleVideoChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      videos: [...e.target.files],
    });
  };

  // Handle multiple file input change for videos
  const handleDocumentChangeReports = (e) => {
    setReportsData({
      ...reportsData,
      documents: [...e.target.files],
    });
  };
  
  // Handle form submission
  const handleSubmitReports = async (e) => {
    e.preventDefault();
    setHandleReportsSubmitBtn(true);
    const formData = new FormData();
    formData.append('apiType', 'upload-reports');
    formData.append('title', reportsData.title);
    formData.append('description', reportsData.description);
    formData.append('department', reportsData.department);

    // Append multiple images to FormData
    reportsData.images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    // Append multiple videos to FormData
    reportsData.videos.forEach((video, index) => {
      formData.append(`videos[${index}]`, video);
    });

     // Append multiple documents to FormData
     reportsData.documents.forEach((document, index) => {
        formData.append(`documents[${index}]`, document);
      });

    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-news`, formData, {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setStatusReports('Report uploaded successfully!');
        setReportsData({
          title: '',
          description: '',
          department: '',
          images: [],
          videos: [],
          documents: [],
        });
        setHandleReportsSubmitBtn(false);
      } else {
        setStatusReports('Failed to upload Report.');
        setHandleReportsSubmitBtn(false);
      }
    } catch (error) {
      setStatusReports('An error occurred while uploading Report.');
      setHandleReportsSubmitBtn(false);
    }
  };


  const [getReports, setGetReports] = useState([]);
const fetchReports = async () => {
      const formData = new FormData();
      formData.append('apiType', 'get-reports');
      formData.append('department', 'Communication');
    try {
          const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
          setGetReports(response.data);
          //console.log(response.data);

          setTimeout(function () {
            $('#table').DataTable({
              retrieve: true, // this hides the errors
              pageLength: 5,
              lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
              layout: {
                topEnd: {
                    search: {
                        text: '',
                        placeholder: 'Search here'
                    }
                }
            }
            })
          }, 2000)
          
      } catch (error) {
        alert('An error occurred while retrieving Reports. Check Your Internet Connectivity');
      }
    };    
    useEffect(() => {
        fetchReports();
    }, []);

    
  const [getNewsItems, setGetNewsItems] = useState([]);
  const fetchNewsItems = async () => {
        const formData = new FormData();
        formData.append('apiType', 'get-news');
        formData.append('department', 'Communication');
      try {
            const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setGetNewsItems(response.data);
            console.log(response.data);
  
            setTimeout(function () {
              $('#tableNews').DataTable({
                retrieve: true, // this hides the errors
                pageLength: 5,
                lengthMenu: [5,10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
                layout: {
                  topEnd: {
                      search: {
                          text: '',
                          placeholder: 'Search here'
                      }
                  }
              }
              })
            }, 2000)
            
        } catch (error) {
          alert('An error occurred while retrieving News Items. Check Your Internet Connectivity');
        }
      };    
      useEffect(() => {
          fetchNewsItems();
      }, []);

  return (
    <>

<div>
<HeaderAdmin />

  <main>

  <div class="container">

  <div hidden={handleLoader ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>

  <section id="news" class="mt-5 mb-4">

          <h2 class="mdb-color-text darken-3 text-uppercase font-weight-bold mb-2 pt-4 wow fadeIn" data-wow-delay=".2s">Communications Department</h2>
<hr class=" mb-4" />

<MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
            <MDBIcon fas icon='edit' className='me-2' /> Upload News
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
            <MDBIcon fas icon='edit' className='me-2' /> Upload Reports
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
            <MDBIcon fas icon='table' className='me-2' /> View News
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
            <MDBIcon fas icon='table' className='me-2' /> View Reports
          </MDBTabsLink>
        </MDBTabsItem>

      </MDBTabs>

    
      <MDBTabsContent>

        <MDBTabsPane open={iconsActive === 'tab1'}> 

        <h2 className="text-center mb-4">Upload News Item</h2>
      {status && <div className="alert alert-info text-center">{status}</div>}
      {statusReports && <div className="alert alert-info text-center">{statusReports}</div>}
      <form onSubmit={handleSubmit} className="border p-4 shadow-sm rounded">
        
        <div className="form-group mb-3">
          <label htmlFor="title" className="form-label">Title <span class='red-text'>*</span> </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={newsData.title}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description" className="form-label">Description / Narration <span class='red-text'>*</span> </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="4"
            value={newsData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        <div class = 'row'>
        <div className="mb-3 col-md-3">
          <label htmlFor="category" className="form-label">Category <span class='red-text'>*</span></label>
          <select
            className="form-control"
            id="category"
            name="category"
            value={newsData.category}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Category</option>
            <option>Inreach</option>
            <option>Outreach</option>
          </select>
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="images" className="form-label">Upload Images</label>
          <input
            type="file"
            className="form-control"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="videos" className="form-label">Upload Videos</label>
          <input
            type="file"
            className="form-control"
            id="videos"
            name="videos"
            accept="video/*"
            multiple
            onChange={handleVideoChange}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="documents" className="form-label">Upload Documents</label>
          <input
            type="file"
            className="form-control"
            id="documents"
            name="documents"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.odt,.ods,.odp"
            multiple
            onChange={handleDocumentChange}
          />
        </div>

        </div>

        <button type="submit" className="btn btn-primary w-100"
         disabled={handleNewsItemSubmitBtn}>Submit News Item       
         <div class="spinner-border" role="status" hidden = {!handleNewsItemSubmitBtn}></div>
         </button>
      </form>
    
        </MDBTabsPane >

        <MDBTabsPane open={iconsActive === 'tab2'} >
            
      <h2 className="text-center mb-4">Upload Reports</h2>
      {status && <div className="alert alert-info text-center">{status}</div>}
      <form onSubmit={handleSubmitReports} className="border p-4 shadow-sm rounded">
        
        <div className="form-group mb-3">
          <label htmlFor="title" className="form-label">Title <span class='red-text'>*</span> </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={reportsData.title}
            onChange={handleInputChangeReports}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description" className="form-label">Description / Narration <span class='red-text'>*</span> </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="4"
            value={reportsData.description}
            onChange={handleInputChangeReports}
            required
          ></textarea>
        </div>

        <div class = 'row'>
        <div className="mb-3 col-md-3">
          <label htmlFor="department" className="form-label">Department <span class='red-text'>*</span></label>
          <select
            className="form-control"
            id="department"
            name="department"
            value={reportsData.department}
            onChange={handleInputChangeReports}
            required
          >
            <option value="">Select Department</option>
            <option>Director</option>
            <option>Chairperson</option>
            <option>Secretariat</option>
            <option>Treasury</option>
            <option>Planning</option>
            <option>Parliamentarian</option>
            <option>Projects</option>
            <option>Education</option>
            <option>Communication</option>
            <option>Chaplaincy</option>
          </select>
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="images" className="form-label">Upload Images</label>
          <input
            type="file"
            className="form-control"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageChangeReports}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="videos" className="form-label">Upload Videos</label>
          <input
            type="file"
            className="form-control"
            id="videos"
            name="videos"
            accept="video/*"
            multiple
            onChange={handleVideoChangeReports}
          />
        </div>

        <div className="mb-3 col-md-3">
          <label htmlFor="documents" className="form-label">Upload Documents</label>
          <input
            type="file"
            className="form-control"
            id="documents"
            name="documents"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.odt,.ods,.odp"
            multiple
            onChange={handleDocumentChangeReports}
          />
        </div>


        </div>

        <button type="submit" className="btn btn-primary w-100"
         disabled={handleReportsSubmitBtn}>Submit Report       
         <div class="spinner-border" role="status" hidden = {!handleReportsSubmitBtn}></div>
         </button>
      </form>
    
        </MDBTabsPane>

        <MDBTabsPane open={iconsActive === 'tab3'} >

            
      <h2 className="text-center mb-4">View News Items</h2>

      <table
                        id='tableNews'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getNewsItems.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.created_at}</td>
                                <td>{val.title}</td>
                                <td>
                                {expandedNews[index] ? val.description : val.description.split(' ').slice(0, 10).join(' ')}
                      {val.description.split(' ').length > 10 && (
                        <span>
                          ... <button onClick={() => toggleContentLatestNews(index)} className="btn btn-link p-0">{expandedNews[index] ? 'Hide' : 'View More'}</button>
                        </span>
                      )}

                                </td>
                                <td>
                                    
                                <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    data-toggle='modal'
    data-target='#modalViewPlotsCustomers'
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-file"></span>View Documents</a>
)}

           </div>
          </li></ul>


                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

     
     </MDBTabsPane>

        <MDBTabsPane open={iconsActive === 'tab4'} >

            
<h2 className="text-center mb-4">View Reports</h2>
   
<table
                        id='table'
                        class='table table-striped table-bordered table-hover table-sm nowrap display'
                        width='100%'
                      >
                        <thead class='form-header text-uppercase ocean blue-gradient'>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getReports.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.created_at}</td>
                                <td>{val.title}</td>
                                <td>{val.description}</td>
                                <td>
                                    
                                <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    data-toggle='modal'
    data-target='#modalViewPlotsCustomers'
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
><span class = "fa fa-file"></span>View Documents</a>
)}

           </div>
          </li></ul>


                                </td>

                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </table>

  </MDBTabsPane>

      </MDBTabsContent>

</section>
</div>


 

  </main>
 
        
      </div>
    </>
  )
}

export default Communication
