import { useRef, useState, useEffect, useContext } from 'react'
// Import FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'
import { faUserTie, faChair, faFileAlt, faPiggyBank, faChartLine, faGavel, faClipboardList, faBook, faComments, faPrayingHands } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';

const Announcements = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    const [announcements, setAnnouncements] = useState([]);
    const [reportDepartment, setReportDepartment] = useState("");
    let [handleLoader, setHandleLoader] = useState(false);

    const [expandedAnnouncement, setExpandedAnnouncement] = useState({});
    const toggleContentLatestAnnouncement = (index) => {
      setExpandedAnnouncement(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };
 
  const viewAannouncements = async (dept) => {
    setHandleLoader(true);
    const formData = new FormData();
      formData.append('apiType', 'get-announcements');
      formData.append('department', dept);
    try {
        const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setAnnouncements(response.data);
          setHandleLoader(false);
          //console.log(response.data);
      } catch (error) {
        alert('An error occurred while retrieving Report. Check Internet Connection');
        setHandleLoader(false);
      }
      
    };

    useEffect(() => {
        viewAannouncements();
    }, []);


    let [fileDoc, setFileDoc] = useState('')
  let [givenFile, setGivenFile ]= useState('')

  const downloadReport = file => {
    //alert(file);
    setFileDoc(file);
    setGivenFile(`${file}`);
}

    useEffect(() => {
      if(fileDoc != '')
      downloadDoc();
    }, [fileDoc]);
    
    const downloadDoc = async () => {
     //alert("Your Download Will Start Soon")
      try {
              const response = await axios.get(server_app_api_php+'/server/downloadReports.php?file='+givenFile, {
                  responseType: 'blob'
              });
              if (response.status === 200) {
                //alert(response.data);
                  const blob = new Blob([response.data], { type: 'application/octet-stream' });
                  const downloadLink = document.createElement('a');
                  downloadLink.href = window.URL.createObjectURL(blob);
                  downloadLink.download = fileDoc;
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
              }
          } catch (error) {
              alert('Error downloading file:', error);
          }
          setFileDoc('')
          setGivenFile('')
    }

  return (
    <div>
        <Header />
        <main class='mt-5'>
    <div className="container my-5 mt-5">
   
        <div className="row">
        <div className="col-12 text-center mb-4 mt-5">
        <h2 className="text-center mb-4 mt-5">Announcements</h2>
        </div>
        
        <div hidden={!handleLoader}>
        <div id="overlayer"></div>
       <div class="loader d-flex justify-content-center">
     <div class="spinner-border white-text" style={{width: '10rem', height: '10rem'}} role="status">
         <span class='visually-hidden'>Retrieving Reports...</span>
       </div>
     </div>
     </div>
        
        {announcements.map((val, index) => {
                      return (
                        <>
                          <div class='col-xl-4 col-md-6 mb-4'>
                            {/*<!-- Panel -->*/}
                            <div class='card'>
                              <div
                                class='card-header text-uppercase white-text info-color'>
                                {val.created_at}
                              </div>

                              <div class='row mt-1'>
                                <div class='col-md-12 col-12 text-left ml-3 pr-5 pl-3 wrap'>
                                  <div class='font-small grey-text'>
                                  Title :
                                    <span class='ml-1 mt-1 mb-1 font-weight-bold white-text'>
                                      {val.title}
                                    </span>
                                  </div>
                                  <div class='mt-1 font-small grey-text'>
                                    Description :
                                    <span class='mt-1 font-weight-bold white-text wrap'>
                                    {expandedAnnouncement[index] ? val.description : val.description.split(' ').slice(0, 10).join(' ')}
                      {val.description.split(' ').length > 10 && (
                        <span>
                          ... <button onClick={() => toggleContentLatestAnnouncement(index)} className="btn btn-link p-0 orange-text">{expandedAnnouncement[index] ? 'Hide' : 'View More'}</button>
                        </span>
                      )}
                                    </span>
                                  </div>
                                  <ul class="nav navbar-nav nav-flex-icons ml-auto" >
	  <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect" href="" id="userDropdown" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="clearfix d-none d-sm-inline-block">Attachments Here</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" >

{val.media_urlsImages.length > 0 && (	
<a class="dropdown-item"
    onClick={() => { downloadReport(val.media_urlsImages)  }}
><span class = "fa fa-image"></span>View Images</a>
)}

{val.media_urlsVideos.length > 0 && (
<a class="dropdown-item"
onClick={() => { downloadReport(val.media_urlsVideos)  }}
><span class = "fa fa-video"></span>Play Videos</a>
)}

{val.media_urlsDocuments.length > 0 && (
<a class="dropdown-item"
onClick={() => { downloadReport(val.media_urlsDocuments)  }}
><span class = "fa fa-file"></span>View Documents</a>
)}
           </div>
          </li></ul>
                                </div>
                              </div>
                              {/*<!-- Card content -->*/}
                            </div>
                            {/*<!-- Panel -->*/}
                          </div>
                        </>
                      )
                    })}

      </div>
    </div>
    </main>

    <Footer />
    </div>
  );
};

export default Announcements;
