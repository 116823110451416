const Header = () => {
return (
    <>


  <header>

    <nav class="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar">
      <div class="container">
        <a class="navbar-brand" href=".">
          <strong>WC - AY</strong>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          
          <ul class="navbar-nav mr-auto smooth-scroll">
            <li class="nav-item">
              <a class="nav-link" href=".">Home
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="Books" data-offset="100">Books</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="Reports" data-offset="100">Reports</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="Announcements" data-offset="100">Announcements</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="News" data-offset="100">News</a>
            </li> 
            <li class="nav-item">
              <a class="nav-link" href="MGs" data-offset="100">MGs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="SYLs" data-offset="100">SYLs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-offset="100">Leaders</a>
            </li> 
            <li class="nav-item">
              <a class="nav-link" href="#" data-offset="100">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" data-offset="100">Contact</a>
            </li>
          </ul>


          <ul class="navbar-nav nav-flex-icons">
            <li class="nav-item">
              <a class="nav-link">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <i class="fab fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
  </header>



</>
    );

}
export default Header