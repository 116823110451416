import logo from './logo.svg'
//import './App.css'
import { Routes, Route } from 'react-router-dom'

import Missing from './components/Missing'
import Layout from './components/Layout'
import Home from './components/Home'
import Books from './components/books'
import MGs from './components/MGs'
import SYLs from './components/SYLs'
import Adventurers from './components/Adventurers'
import Pathfinders from './components/Pathfinders'
import Ambassadors from './components/Ambassadors'
import YoungAdults from './components/YoungAdults'
import PCM from './components/PCM'
import News from './components/News'
import Reports from './components/Reports'
import Announcements from './components/Announcements'
import Dashboard from './components/Dashboard'
import Communication from './components/committees/Communication'
import Secretariat from './components/committees/Secretariat'
import Chairperson from './components/committees/Chairperson'
import Chaplaincy from './components/committees/Chaplaincy'
import Director from './components/committees/Director'
import Education from './components/committees/Education'
import Parliamentarian from './components/committees/Parliamentarian'
import Planning from './components/committees/Planning'
import Projects from './components/committees/Projects'
import Treasury from './components/committees/Treasury'


function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='*' element={<Missing />} />
        <Route path='/' element={<Home />} />
        <Route path='Books' element={<Books />} />
        <Route path='MGs' element={<MGs />} />
        <Route path='SYLs' element={<SYLs />} />
        <Route path='Adventurers' element={<Adventurers />} />
        <Route path='Pathfinders' element={<Pathfinders />} />
        <Route path='Ambassadors' element={<Ambassadors />} />
        <Route path='YoungAdults' element={<YoungAdults />} />
        <Route path='PCM' element={<PCM />} />
        <Route path='News' element={<News />} />
        <Route path='Reports' element={<Reports />} />
        <Route path='Announcements' element={<Announcements />} />
        <Route path='Dashboard' element={<Dashboard />} />
        <Route path='Communication' element={<Communication />} />
        <Route path='Secretariat' element={<Secretariat />} />
        <Route path='Chairperson' element={<Chairperson />} />
        <Route path='Chaplaincy' element={<Chaplaincy />} />
        <Route path='Director' element={<Director />} />
        <Route path='Education' element={<Education />} />

        <Route path='Parliamentarian' element={<Parliamentarian />} />
        <Route path='Planning' element={<Planning />} />
        <Route path='Projects' element={<Projects />} />
        <Route path='Treasury' element={<Treasury />} />
      </Route>
    </Routes>
  )
}

export default App
